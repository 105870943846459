<template>
  <v-container>
    <v-row v-if="playlist">
      <draggable
        v-model='scenesList'
        class="row"
        :disabled="!(isOperator && isLicenseValid)"
      >
        <v-col v-for="scene in playlist.scenes" :key="scene.id" md="auto" class="ma-0 pa-0">
          <scene-card :scene="scene" :playlist="playlist" @delete-scene="deleteScene(scene)"></scene-card>
        </v-col>
      </draggable>
    </v-row>
    <v-row
      v-if="isOperator && isLicenseValid"
      justify="center"
      align="center"
      class="mt-10"
    >
      <v-dialog v-model="dialog" max-width="300px" scrollable>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary--text"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('scene_list.add_cue_button') }}
          </v-btn>                   
        </template>
        <v-card>
          <v-card-title>{{ $t('scene_list.add_cue_card.title') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <v-radio-group v-model="newScene.cue">
              <v-radio
                v-for="cue in cues"
                :key="cue.id"
                :label="cue.filename"
                :value="cue.id"
                >
              </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="primary--text"
              @click="closeSceneDialog"
            >
              {{ $t('scene_list.add_cue_card.close_button') }}
            </v-btn>
            <v-btn color="primary--text" @click="saveNewScene(playlist)">
              {{ $t('scene_list.add_cue_card.save_button') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

import sceneCard from "./Scene";

export default {
  props: ["playlist", 'isOperator'],
  components: { 
    draggable,
    sceneCard,
  },
  data: () => ({
    dialog: false,
    newScene: {
      cue: undefined,
      playlist: undefined,
      repeat_value: 1,
      order: 0,
    },
    defaultScene: {
      cue: undefined,
      playlist: undefined,
      repeat_value: 1,
      order: 0,
    },
  }),
  methods: {
    getGif(cue) {
      return `${this.$store.state.mediaPath}${cue.gif}`
    },
    resetGif(cue) {
      let gif = cue.gif
      cue.gif = ""
      cue.gif = `${gif}?a=${Math.random()}`
    },
    closeSceneDialog() {
      this.dialog= false
      this.newScene = Object.assign({}, this.defaultScene)
    },
    saveNewScene(playlist) {
      this.newScene.playlist = playlist.id
      this.newScene.order = playlist.scenes.length
      this.$store.dispatch("createScene", this.newScene);
      this.closeSceneDialog()
    },
    deleteScene(scene) {
      this.$store.dispatch("deleteScene", scene).then(() => {
        this.updateSceneList(this.scenesList)
      })
      
    },
    changeScene(scene){
      this.$store.dispatch("changeScene", scene);
    },
    updateSceneList(sceneList) {
      sceneList.forEach((scene, index) => scene.order = index)
      let playlistId = this.playlist.id
      this.$store.dispatch('updateScenes', {scenesList: sceneList, playlistId: playlistId})
    }
  },
  computed: {
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    scenesList: {
      get() {
        return this.playlist.scenes
      },
      set(value) {
        this.updateSceneList(value)
      }
    },
    cues() {
      return this.$store.state.cues;
    }
  }
};
</script>

<template>
  <div>
    <p class="title text-center">{{ $t('playlists_page.title') }}</p>

    <v-row
      v-if="isOperator && isLicenseValid" 
      class="justify-start ml-4"
    >
      <v-dialog
        v-model="dialogPlaylist"
        persistent
        max-width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary--text"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('playlists_page.create_playlist_button') }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('playlists_page.create_playlist_card.title') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form v-model="isValid">
                <v-row justify="center">
                  <v-col>
                    <v-text-field
                      :label="$t('playlists_page.create_playlist_card.label')"
                      v-model="editedPlaylist.name"
                      :rules="[rules.required, rules.counter]"
                      maxlength="64"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('playlists_page.create_playlist_card.required_field') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary--text" @click="close">
              {{ $t('playlists_page.create_playlist_card.cancel_button') }}
            </v-btn>
            <v-btn :disabled="!isValid" color="primary--text" @click="save">
              {{ $t('playlists_page.create_playlist_card.save_button') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
    </v-row>
    <div class="mt-6">
      <v-row v-for="playlist in playlists" :key="playlist.id" justify="center">
          <v-card align="center" width="90%">
            <v-toolbar
              align="end"
              color="white"
              dense
            >
              <v-row align="center">
                <v-col>
                  <v-text-field
                    v-model="playlist.name"
                    solo
                    flat
                    background-color='transparent'
                    :rules="[rules.counter]"
                    dense
                    hide-details
                    maxlength="64"
                    v-on:keyup.enter="$event.target.blur()"
                    @change="savePlaylist(playlist)"
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="px-0">
                  <strong>{{ $t('playlists_page.duration') }}: <span v-text="getDuration(playlist)"></span></strong>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    v-if="isOperator && isLicenseValid"
                    icon
                    @click="deletePlaylist(playlist)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>

              </v-row>
            </v-toolbar>
            <v-card-text>
              <scene-list :playlist="playlist" :isOperator="isOperator"></scene-list>
            </v-card-text>
          </v-card>
      </v-row>
    </div>
      <v-dialog v-model="dialogDelete" width="unset">
        <v-card height="auto">
          <v-card-title v-show="relatedEvents.length > 0" class="headline">{{ $t('playlists_page.delete_card.headline') }}:</v-card-title>
          <ul v-if="relatedEvents" class="px-10 pb-10">
            <li v-for="(event, key) in relatedEvents" :key="key">{{ event }}</li>
          </ul>
          <v-card-subtitle class="headline">{{ $t('playlists_page.delete_card.are_you_sure') }}</v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary--text" @click="closeDelete">{{ $t('playlists_page.delete_card.cancel_button') }}</v-btn>
            <v-btn color="primary--text" @click="deleteItemConfirm">{{ $t('playlists_page.delete_card.ok_button') }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import sceneList from '@/components/SceneList'
import Api from "@/api/api"


export default {
  data: () => ({
    dialogDelete: false,
    dialogPlaylist: false,
    dialogScene: false,
    isValid: false,
    editedIndex: -1,
    editedPlaylist: {name: '', fade_in: 0, fade_out: 0},
    defaultPlaylist: {name: '', fade_in: 0, fade_out: 0},
    newScene: {
      cue: undefined,
      playlist: undefined,
      repeat_value: 1,
      order: 0,
    },
    defaultScene: {
      cue: undefined,
      playlist: undefined,
      repeat_value: 1,
      order: 0,
    },
    deletedPlaylist: {},
    relatedEvents: {},
  }),
  components: {sceneList},
  created() {
    this.$store.dispatch("getPlaylists");
    this.$store.dispatch("getCues");
  },
  computed: {
    rules() {
      return {
        counter: value => value.length <= 64 || this.$t('playlists_page.create_playlist_card.rules.maximum'),
        isInteger: value => parseInt(value) == value || this.$t('playlists_page.create_playlist_card.rules.integer'),
        required: value => value != '' || this.$t('playlists_page.create_playlist_card.rules.required'),
      }
    },
    isOperator() {
      return this.$store.getters.isOperator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    cues() {
      return this.$store.state.cues;
    },
    playlists() {
      return this.$store.state.playlists;
    }
  },
  methods: {
    getDuration(playlist) {
      let duration = 0
      for (const scene of playlist.scenes) {
        duration = duration + scene.duration - scene.transition_time
      }
      return duration.toFixed(1)
    },
    close () {
      this.dialogPlaylist = false
      this.$nextTick(() => {
        this.editedPlaylist = Object.assign({}, this.defaultPlaylist)
        this.editedIndex = -1
      })
      this.$forceUpdate()
    },
    save () {
      if (this.editedIndex > -1) {
        this.$store.dispatch('updatePlaylist', this.editedPlaylist)
      } else {
        this.$store.dispatch('createPlaylist', this.editedPlaylist)
      }
      this.close()
    },
    addPlaylist(){
      this.$store.dispatch("createPlaylist");
    },
    savePlaylist(playlist){
      this.$store.dispatch('updatePlaylist', playlist)
    },
    deletePlaylist(playlist){
      this.deletedPlaylist = playlist
      this.getRelatedEvents(playlist.id)
      this.dialogDelete = true
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.deletedPlaylist = {}
        this.relatedEvents = []
      })
    },
    deleteItemConfirm() {
      this.$store.dispatch('deletePlaylist', this.deletedPlaylist.id)
      this.closeDelete()
    },
    getRelatedEvents(id) {
      Api.getRelatedEvents(id)
      .then((relatedEvents) => {
        this.relatedEvents = relatedEvents.map((event) => {return event.title})
      })
      
    },
  }
};
</script>

<style>
</style>
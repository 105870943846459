<template>
  <v-card
    class="my-2 pb-2"
    width="220"
    :color="scene.cue_detail.no_file ? 'red' : undefined"
  >
    <div justify="end" align="right">
      <v-btn
        justify-end
        text
        depressed
        small
        min-width="20"
        height="10"
        :right="true"
        
        v-on:click="deleteScene"
        class="mx-0 px-0"
      >X</v-btn>
    </div>
    <v-card-text
      class="justify-center pt-0 pb-2 px-1 text-body-1 font-weight-bold text-truncate"
    >
      {{ makeText(scene.cue_detail.filename) }}
    </v-card-text>
    <v-img v-if="scene.cue_detail.gif" max-width="200" max-height="200"
        :src='getGif(scene.cue_detail)' @click='resetGif(scene.cue_detail)'>
    </v-img>
    <v-img v-else
      max-width="200" max-height="200"
      style="opacity:0.1"
      :src="require('@/assets/gifs/stat.gif')">
    </v-img>
    <v-row class="my-auto mx-auto" justify="center">
      <span class="py-1">{{ $t('scene.duration') }}: {{ $tc('scene.seconds', scene.duration) }}</span>
    </v-row>
    <ValidationObserver ref="form">
    <v-row align="center" class="mx-auto my-auto py-2 px-2">
      <v-col cols="1">
        <v-row justify="end">
          <v-icon v-if="choiceTransitionInIcon"
            disabled
            small
          >$TransitionInOnNewIcon</v-icon>
          <v-icon v-else
            :disabled="scene.order === 0"
            small
            @click="changeToTransitionIn"
          >$TransitionInOffNewIcon</v-icon>
        </v-row>
        <v-row justify="end">
          <v-icon
            :disabled="isFadeInButtonPressed"
            :color="choiceFadeInIconColor"
            small
            @click="changeToFadeIn"
          >$FadeInIcon</v-icon>
        </v-row>
      </v-col>
      <div v-if="isTransitionInButtonPressed">
        <ValidationProvider
          :rules="transitionInRules"
          immediate
          mode="aggressive"
          v-slot= "{ valid }"
        >
          <input 
            :class="valid ? 'text-input' : 'text-input-error'"
            v-model="prevSceneTransitionTime"
            :readonly="!(isOperator && isLicenseValid)"
            @change="changePrevScene()"
            type="number"
          >
        </ValidationProvider>
      </div>
      <div v-else-if="isFadeInButtonPressed">
        <ValidationProvider
          :rules="fadeInRules"
          immediate
          mode="aggressive"
          v-slot= "{ valid }"
        >
          <input
            :class="valid ? 'text-input' : 'text-input-error'"
            v-model="scene.fade_in"
            :readonly="!(isOperator && isLicenseValid)"
            @change="changeScene(scene)"
            type="number"
          >
        </ValidationProvider>
      </div>    
        <input v-else
          class="text-input"
          disabled
          :value="0"
          type="number"
        >
      <v-col class="pa-0">
        <ValidationProvider
          rules="required|min_value:1|integer"
          immediate
          mode="aggressive"
          v-slot= "{ valid }"
        >
          <div :class="valid ? 'repeat-box' : 'repeat-box-error'">
            <v-icon class="inputIcon">mdi-repeat</v-icon>
            
              <input
                class="repeat-input"
                :readonly="!(isOperator && isLicenseValid)"
                v-model="scene.repeat_value"
                @change="changeScene(scene)"
                type="number"
              >
          </div>
        </ValidationProvider>
      </v-col>
      <div v-if="isTransitionOutButtonPressed">
        <ValidationProvider
          :rules="transitionOutRules"
          immediate
          mode="aggressive"
          v-slot= "{ valid }"
        >
          <input 
            :class="valid ? 'text-input' : 'text-input-error'"
            v-model="scene.transition_time"
            :readonly="!(isOperator && isLicenseValid)"
            @change="changeScene(scene)"
            type="number"
          >
        </ValidationProvider>
      </div>
      <div v-else-if="isFadeOutButtonPressed">
        <ValidationProvider
          :rules="fadeOutRules"
          immediate
          mode="aggressive"
          v-slot= "{ valid }"
        >
        <input 
          :class="valid ? 'text-input' : 'text-input-error'"
          v-model="scene.fade_out"
          :readonly="!(isOperator && isLicenseValid)"
          @change="changeScene(scene)"
          type="number"
        >
        </ValidationProvider>
      </div>
      <input v-else
        class="text-input"
        disabled
        :value="0"
        type="number"
      >
      
      <v-col cols="1">
        <v-row>
          <v-icon v-if="choiceTransitionOutIcon"
            small
            disabled
            @click="changeToTransitionOut"
          >$TransitionOutOnNewIcon</v-icon>
          <v-icon v-else
            small
            @click="changeToTransitionOut"
          >$TransitionOutOffNewIcon</v-icon>
        </v-row>
        <v-row>
          <v-icon 
            small
            :disabled="isFadeOutButtonPressed"
            :color="choiceFadeOutIconColor"
            @click="changeToFadeOut"
          >$FadeOutIcon</v-icon>
        </v-row>
      </v-col>
    </v-row>
    </ValidationObserver>
  </v-card>
</template>

<script>

export default {
  props: ["scene", "playlist"],
  data: function() {
    return {
    }
  },
  computed: {
    fadeInRules() {
      let max_value = this.scene.cue_detail.duration - this.scene.fade_out - this.scene.transition_time
      return {
        required: true,
        min_value: 0,
        integer: true,
        max_value: max_value,
      }
    },
    fadeOutRules() {
      let max_value = this.scene.cue_detail.duration - this.scene.fade_in - this.prevScene.transition_time
      return {
        required: true,
        min_value: 0,
        integer: true,
        max_value: max_value,
      }
    },
    transitionInRules() {
      let current_scene_max_value = this.scene.cue_detail.duration - this.scene.fade_out - this.scene.transition_time
      let next_scene_max_value = this.prevScene.cue_detail.duration - this.prevScene.fade_out
      let max_value = current_scene_max_value > next_scene_max_value ? next_scene_max_value : current_scene_max_value
      return {
        required: true,
        min_value: 0,
        integer: true,
        max_value: max_value,
      }
    },
    transitionOutRules() {
      let current_scene_max_value = this.scene.cue_detail.duration - this.scene.fade_in
      let next_scene_max_value = this.nextScene.cue_detail.duration - this.nextScene.fade_out - this.nextScene.transition_time
      let max_value = current_scene_max_value > next_scene_max_value ? next_scene_max_value : current_scene_max_value
      return {
        required: true,
        min_value: 0,
        integer: true,
        max_value: max_value,
      }
    },
    isFadeInButtonPressed() {
      return this.scene.fade_in !== 0
    },
    isFadeOutButtonPressed() {
      return this.scene.fade_out !== 0
    },
    isTransitionInButtonPressed() {
      return this.scene.order > 0 ? this.prevSceneTransitionTime !== 0 : false
    },
    isTransitionOutButtonPressed(){
      return this.scene.transition_time !== 0
    },
    prevScene() {
      let isFirstScene = this.scene.order === 0
      if (isFirstScene) {
        let lastScene = this.playlist.scenes[this.playlist.scenes.length- 1]
        return lastScene
      } else {
        let prevScene = this.playlist.scenes[this.scene.order - 1]
        return prevScene
      }
    },
    nextScene() {
      let isLastScene = this.scene.order + 1 == this.playlist.scenes.length
      if (isLastScene) {
        return this.playlist.scenes[0]
      } else {
        return this.playlist.scenes[this.scene.order + 1]
      }
    },
    isOperator() {
      return this.$store.getters.isOperator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    isPrevTransactionSet() {
      if (this.scene.order > 0) {
        if (this.prevSceneTransitionTime != 0) {
          return true
        }
      }
      return false
    },
    prevSceneTransitionTime: {
      get: function() {
        if (this.scene.order > 0) {
          return this.prevScene.transition_time
        }
        return 0
      },
      set: function(newValue) {
        this.prevScene.transition_time = newValue
      }
    },
    choiceFadeInIconColor() {
      if (this.scene.order > 0) {
        if (this.scene.fade_in !== 0 && this.prevSceneTransitionTime === 0) {
          return '#737373'
        }
      } else if (this.scene.fade_in !== 0) {
        return '#737373'
      }
      return '#D9D9D9'
    },
    choiceFadeOutIconColor() {
      if (this.scene.fade_out !== 0) {
        return '#737373'
      }
      return '#D9D9D9'
    },
    choiceTransitionInIcon() {
      if (this.scene.order > 0) {
        if (this.prevSceneTransitionTime > 0) {
          return true
        }
      }
      return false
    },
    choiceTransitionOutIcon() {
      if (this.scene.transition_time !== 0) {
        return true
      }
      return false
    },
  },
  methods: {
    changeToTransitionIn() {
      if (this.scene.order != 0) {
        this.prevScene.fade_out = 0
        this.prevSceneTransitionTime = 1
        this.changeScene(this.prevScene)
      }
      this.scene.fade_in = 0
      this.changeScene(this.scene)
    },
    changeToTransitionOut() {
      this.scene.fade_out = 0
      this.scene.transition_time = 1
      this.changeScene(this.scene)
    },
    changeToFadeIn() {
      if (this.scene.order != 0) {
        this.prevSceneTransitionTime = 0
        this.changeScene(this.prevScene)
      }
      this.scene.fade_in = 1
      this.changeScene(this.scene)
    },
    changeToFadeOut() {
      this.scene.transition_time = 0
      this.scene.fade_out = 1
      this.changeScene(this.scene)
    },
    makeText(filename) {
      return filename.replace('.cue', '')
    },
    getGif(cue) {
      return `${this.$store.state.mediaPath}${cue.gif}`
    },
    resetGif(cue) {
      let gif = cue.gif
      cue.gif = ""
      cue.gif = `${gif}?a=${Math.random()}`
    },
    deleteScene() {
      this.$emit('delete-scene')
    },
    changePrevScene() {
        this.changeScene(this.prevScene)
    },
    changeScene(scene){
      let isFormValid = this.$refs.form.flags.valid
      if (isFormValid) {
        this.$store.dispatch("changeScene", scene);
      } else {
        let errors = this.$refs.form.errors
        console.log(errors)
      }
    },
  },
  watch: {
    prevSceneTransitionTime(newVal, oldVal){
      if (newVal > 0 && oldVal === 0){
        this.scene.fade_in = 0
        this.changeScene(this.scene)
      }
    }
  }
}
</script>

<style>
  .text-input {
      width: 24px;
      height: 34px;
      font-size: 12px;
      text-align: center;
      border: 1px solid;
      border-radius: 4px;
      margin-left: 4px;
      margin-right: 4px;
  }
  .text-input-error {
      width: 24px;
      height: 34px;
      font-size: 12px;
      text-align: center;
      border: 1px solid;
      border-radius: 4px;
      border-color: red;
      margin-left: 4px;
      margin-right: 4px;
  }
  .repeat-box {
    display: flex;
    align-items: center;
    height: 34px;
    border: 1px solid;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
  }
  .repeat-box-error {
    display: flex;
    align-items: center;
    height: 34px;
    border: 1px solid;
    border-radius: 4px;
    border-color: red;
    margin-left: 4px;
    margin-right: 4px;
    vertical-align: middle;
  }
  .repeat-input {
    width: 40px;
    font-size: 14px;
    text-align: center;
  }
  .inputIcon {
    padding: 3px;
  }
  .duration-text {
    margin-bottom: 5px;
  }
  .fade-out-icon {
    fill: currentColor;
  }
  .fade-in-icon {
    fill: currentColor;
  }
  .theme--light.v-icon.v-icon.v-icon--disabled {
    color: #737373 !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

